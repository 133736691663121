import React from 'react'
import { Spinner } from '../Spinner/Spinner'

interface IOutineButton {
  label: string
  handleClick?: any
  type?: any
  isLoading?: boolean
  disabled?: boolean
  color?: string
  icon?: JSX.Element // New prop for icon
}

export function ButtonOutlined({ label, handleClick, type, isLoading, color, disabled, icon }: IOutineButton): JSX.Element {
  return (
    <button
      className={`flex justify-center items-center ${disabled ? 'bg-opacity-60' : ''} border rounded-lg h-10 w-15 md:w-24 text-xs font-light capitalize ${color ? color : 'bg-white-300 text-black'}`}
      onClick={handleClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {icon && <div className="mr-2">{icon}</div>} {/* Conditionally render the icon */}
      {isLoading ? <Spinner /> : label}
    </button>
  );
}
