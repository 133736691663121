import { devInstance } from "../axios/axiosInstance";

export const searchAllFaqs = async (payload: any) => {
    const response = await devInstance.post('/Faq/GetAllFaqs', payload)
    return response
}

export const getAllactiveProducts = async (payload: any) => {
    const response = await devInstance.post('/Product/GetAllActiveProducts', payload)
    return response
}

export const getAllactiveCategories = async (payload: any) => {
    const response = await devInstance.post('/Category/GetAllCategories', payload)
    return response
}

export const deleteFaq = async (payload: any) => {
    const response = await devInstance.post('/Faq/DeleteFaq', payload)
    return response
}

export const addFaq = async (payload: any) => {
    const response = await devInstance.post('/Faq/CreateFaq', payload)
    return response
}

export const fectchCategoriesByProductId = async (payload: any) => {
    const response = await devInstance.post('Category/GetActiveCategoriesByProductId', payload)
    return response
}

export const addCategory = async (payload: any) => {
    const response = await devInstance.post('/Category/CreateCategory', payload)
    return response
}

export const updateFaq = async (payload: any) => {
    const response = await devInstance.put('/Faq/UpdateFaq', payload)
    return response
}
