import { AxiosRequestConfig } from 'axios'
import { getSavedState } from '../localStorage'

export const requestInteceptor = (config: AxiosRequestConfig) => {
    const savedState = getSavedState('cmsUser')
    const cmsUser = savedState?.data?.access_token
console.log(cmsUser, "who");

    config.headers = {
        ...config.headers,

        Authorization: `Bearer ${cmsUser}`,
    }
    return config
}
