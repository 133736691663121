import { ReactElement, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import { deleteLocalState, getSavedState } from "../../utils/localStorage";
import logoutIcon from "../../assets/img/fi-rr-sign-out-alt.svg";

const cmsUser = getSavedState("cmsUser");
type IProfileDropdown = {
  isOpen: boolean;
  setIsOpen: any;
};

export function ProfileDropdown({
  isOpen,
  setIsOpen,
}: IProfileDropdown): ReactElement {
  const navigate = useNavigate();
  // const { resetAll } = useContext(DataCaptureCtx)

  const handleLogout = () => {
    deleteLocalState("cmsUser");
    // resetAll()
    navigate("/");
    // location.reload()
  };
  return (
    <div
      className={
        " fixed overflow-hidden z-40 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all opacity-0 translate-x-full  ")
      }
    >
      <div className="origin-top-right top-4 right-2 absolute sm:w-72 w-[calc(50%)] z-10 rounded-md shadow-lg bg-white opacity-100 focus:outline-none py-2">
        <div className="flex justify-center items-center py-4">
          <img
            src={logoutIcon}
            style={{ cursor: "pointer" }}
            alt="sign out"
            width="15"
            height="15"
            className=""
          />
          <span
            className="text-base font-light cursor-pointer"
            onClick={handleLogout}
          >
            log out
          </span>
        </div>
      </div>
      <div
        className="w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </div>
  );
}
