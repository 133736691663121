import { FC } from 'react'
import ReactPaginate from 'react-paginate'
import { BackCaret } from '../../Icons/BackCaret'
import { ForwardCaret } from '../../Icons/ForwardCaret'
import './pagination.css'

export const PaginationComponent: FC<{
    pageSize: number
    totalCounts: number
    handlePageChange: any
    currentPage: number
    showOnSmallScreen?: boolean
}> = ({ pageSize, totalCounts, handlePageChange, currentPage, showOnSmallScreen }) => {
    const pageCount = Math.ceil(totalCounts / pageSize)

    return (
        <div className="flex justify-between py-4 items-center">
            <div className={`text-gray-800 text-xs  ${showOnSmallScreen? "block px-1":"hidden lg:block px-6"}`}>
                {showOnSmallScreen? "page":  "Showing Page"} {currentPage} of {pageCount}
            </div>

            <div className={`${showOnSmallScreen ? "block":"hidden md:block"}`}>
            <ReactPaginate
                    forcePage={currentPage - 1}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName="paginate"
                    previousLabel={
                        <>
                            Previous <BackCaret className="text-gray-600" />
                        </>
                    }
                    nextLabel={
                        <>
                            Next <ForwardCaret />
                        </>
                    }
                    disabledClassName={'paginate__link--disabled'}
                    activeClassName={'paginate__link--active'}
                    nextLinkClassName="bg-none"
                    previousLinkClassName="bg-none"
                />
            </div>
        </div>
    )
}
