import React, { ReactNode } from 'react'
import { Spinner } from '../Spinner/Spinner'

interface IOutineButton {
  label: ReactNode
  handleClick?: any
  type?: any
  isLoading?: boolean
  disabled? : boolean
  color? : string
  className?: any
}

export function ButtonPrimary({ label, handleClick, type, isLoading, color, disabled }: IOutineButton): JSX.Element {
  return (
    <div>
      <button
      className={`flex justify-center items-center  ${disabled? 'bg-opacity-60': ''}  border rounded-lg h-10 w-56  text-sm font-Inter font-normal ${color? color : 'bg-blue-300  text-white'}`}
      onClick={handleClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {isLoading ? (<Spinner />) : label}
    </button>

    {/* <button
      className={`flex justify-center items-center ${disabled? 'bg-opacity-60': ''}  border rounded-lg h-10 w-24 md:w-36  text-xs font-Inter font-semibold capitalize  ${color? color : 'bg-white-300  text-black'}`}
      onClick={handleClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {isLoading ? (<Spinner />) : label}
    </button> */}
    </div>
    );
}
