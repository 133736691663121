import axios, { AxiosRequestConfig } from 'axios'
import { requestInteceptor } from './requestInterceptors'
import { responseErr, responseInterceptor } from './responseInterceptor'

export const devInstance = axios.create({
    baseURL:
        process.env.NODE_ENV === 'development'
            ? `${process.env.REACT_APP_API_URL}`
            : //@ts-ignore
              `${window.env.passerelleUrl}/api`,
    headers: {}
})

export const makerCheckerInstance = axios.create({
    baseURL:
        process.env.NODE_ENV === 'development'
            ? `${process.env.REACT_APP_API_MAKER_CHECKER_URL}`
            : //@ts-ignore
              `${window.env.makerCheckerUrl}/api`,
    headers: {},
})

devInstance.interceptors.request.use(
        //@ts-ignore
    (config: AxiosRequestConfig) => requestInteceptor(config),
    (error: any) => {
        return Promise.reject(error)
    }
)
devInstance.interceptors.response.use(
    (res) => responseInterceptor(res),
    async (err) => responseErr(err, devInstance, `$`)
)
makerCheckerInstance.interceptors.request.use(
    //@ts-ignore
    (config: AxiosRequestConfig) => requestInteceptor(config),
    (error: any) => {
        return Promise.reject(error)
    }
)
makerCheckerInstance.interceptors.response.use(
    (res) => responseInterceptor(res),
    async (err) => responseErr(err, makerCheckerInstance, '')
)
