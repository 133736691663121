import React, { ReactElement, useState, useEffect } from "react";
import GenericLayout from "../../components/GenericLayout/index";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
// import { useQuery, useMutation } from 'react-query'
import { useQuery } from "@tanstack/react-query";
import { CmsTable } from "./record";
import { SearchInput } from "../../components/UIkit/Search/Search";
import { PaginationComponent } from "../../components/UIkit/Pagination/Pagination";
import { searchAllFaqs } from "../../utils/services/cmsPortal";
import { PageCount } from "../../components/PageCount/PageCount";
import { filterOptions } from "./data";
import Skeleton from "react-loading-skeleton";
import { ErrorCard } from "../../components/UIkit/ErrorCard/ErrorCard";
import { EmptyState } from "../../components/UIkit/EmptyState/EmptyState";
import { ButtonPrimary } from "../../components/UIkit/ButtonPrimary/Button";
import { SideModal } from "../../components/UIkit/AppModal/SideModal";
import AddFaq from "./cmsPortalModal";
import useAllActiveProducts from "../../hooks/useAllActiveProducts";
import useAllActiveCategories from "../../hooks/useAllActiveCategories";
import AddIcon from "../../assets/img/fi-rr-plus-small.svg";
import { AppModal } from "../../components/UIkit/AppModal/AppModal";
import useAllCategoriesProduct from "../../hooks/useAllCategoriesByProduct";



export default function ARAPCmsPortal(): ReactElement {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [reload, setReload] = useState([]);
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState("All");
  const [showModal, setShowModal] = useState<any>();
  const { products, getAllActiveProducts } = useAllActiveProducts();
  const { categories, getAllActiveCategories } = useAllActiveCategories();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');


  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    parameter: {
      query: "",
      status: 0,
      productId: "",
    },
  });

  const handleStatusFilterType = (input: number) => {
    setFilterType(input.toString());
    setFilters({
      ...filters,
      parameter: {
        ...filters.parameter,
            status: input,
          },
    });
  };
  
  const handleFilterType = (input: string) => {
    setFilterType(input)
    setFilters({
        ...filters,
        parameter: {
            ...filters.parameter,
            productId: input,

        },
    })
}

  const handleSearch = (input: string) => {
    setSearchPhrase(input);
  };
  const handlePageChange = (page: { selected: number }) => {
    setFilters({
      ...filters,
      pageNumber: page?.selected + 1,
    });
  };

  const searchAction = () => {
    setFilters({
      ...filters,
      parameter: {
        ...filters.parameter,
        query: searchPhrase,
      },
    });
  };



  const handleClickOpen = () => {
    setShowModal(true);
  };

  const reloadPage = () => {
    window.location.reload(); // Reload the page
  };

  const {
    isLoading,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery(
    [
      filters.pageNumber,
      filters.pageSize,
      filters.parameter.query,
      filters.parameter.productId,
      filters.parameter.status,
    ],
    () => searchAllFaqs(filters),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {
        // console.log('res', res?.data.totalCount)
      },
      onError: (err) => {
        // console.log('err', err)
      },
    }
  );
  

  useEffect(() => {
    getAllActiveCategories();
    getAllActiveProducts();
  }, []);
  useEffect(() => {
    if (searchPhrase === "") {
      searchAction();
    }
  }, [searchPhrase]);

  return (
    <GenericLayout>
      <div className="p-10 h-screen bg-[#ffffff]">
        <h1 className="font-aeonik-pro text-lg font-medium leading-28 tracking-normal text-left">
          FAQs
        </h1>
        <div className="flex justify-center">
        <span className="font-light text-xs mr-[400px]"
        style={{marginBottom: "-36px"}}
        >Filter by</span>
        </div>
        <div className="flex flex-col justify-center items-center xl:flex-row xl:justify-between pt-4">
          {" "}
          <div className="flex flex-col justify-between md:flex-row gap-4 ml-96">
            <PageCount
            //   options={products}
            // options={products?.map((ele: { id: any; productName: any }) => {
            //     return {
            //       value: ele.id,
            //       label: ele.productName,
            //     };
            //   })}
            options={[
              { value: '', label: 'All' },
              ...products?.map((ele: { id: any; productName: any }) => ({
                value: ele.id,
                label: ele.productName,
              })),
            ]}
              handleEntriesCount={handleFilterType}
              entriesCount={filterType}
              type={true}
            />
            <PageCount
              options={filterOptions}
              handleEntriesCount={handleStatusFilterType}
              entriesCount={filterType}
              type={true}
            />
            <SearchInput
              value={searchPhrase}
              handleSearch={handleSearch}
              onAction={searchAction}
              // onChange={searchBarChange}
            />
            <ButtonPrimary
              label={
                <>
                  <span className="mr-2">
                    <img src={AddIcon} alt="Add" />{" "}
                  </span>
                  Add faq
                </>
              }
              handleClick={handleClickOpen}
            />
            {/* <ButtonPrimary label="Add faq" handleClick={handleClickOpen} /> */}
          </div>
        </div>
       

        <div>
          {isLoading && (
            <div className="pt-8">
              <Skeleton className="px-5 py-3 my-2 h-32" count={6} />
            </div>
          )}
          {isError && <ErrorCard />}
          {response?.data?.data?.object.length === 0 && (
            <div className="mt-8">
              <EmptyState />
            </div>
          )}
          {response?.data?.data?.object.length > 0 && (
            <div className="mt-4">
              <CmsTable apiData={response?.data?.data?.object} 
              refetch={reloadPage}/>

              
          
            <PaginationComponent
              pageSize={filters.pageSize}
              totalCounts={response?.data?.data?.count}
              handlePageChange={handlePageChange}
              currentPage={filters.pageNumber}
            />
         
       
            </div>
          )}
        </div>
        {/* <SideModal  setIsOpen={setShowModal}> */}
          
          <AddFaq isOpen={showModal} setIsOpen={setShowModal} />
        {/* </SideModal> */}
      </div>
    </GenericLayout>
  );
}
