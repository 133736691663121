import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAllactiveProducts } from '../utils/services/cmsPortal'


export default function useAllActiveProducts() {
    const [products, setProducts] = useState([])
    const [payload, setPayload] = useState({
        pageSize: 100,
        from: 1,
        parameter: {
            query: ""
        }
    })

    const { refetch } = useQuery(["GetAllActiveProducts"], () => getAllactiveProducts(payload), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setProducts(response?.data?.data?.object)
        },        
    })

    const getAllActiveProducts = () => {
        refetch();
    }

    return { products, getAllActiveProducts, }
}
