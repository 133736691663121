import React, { ReactElement } from "react";
import CloseIcon from "../../../assets/img/modal-close-icon.svg";
interface IModal<T> {
  isOpen: boolean;
  setIsOpen: any;
  data?: T;
  title: string;
  children: React.ReactNode;
  containerClass?: string;
  contentClass?: string;
  childrenContainer?: string;
  handleClose?: () => void;
  showCloseButton?: boolean;
  modalIcon?: React.ReactNode;
}

export function AppModal<Tdata>({
  isOpen,
  setIsOpen,
  title,
  data,
  handleClose,
  containerClass = "w-full max-w-5xl md:w-1/2",
  contentClass = "py-8",
  childrenContainer,
  children,
  showCloseButton = false,
  modalIcon,
}: IModal<Tdata>): ReactElement {
  return (
    <>
      {isOpen ? (
        <>
          <div 
          className="flex justify-center items-center overflow-x-hidden overflow-y-hidden bg-opacity-[0.6] bg-gray-900 fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={`relative  my-6 mx-auto px-4 ${containerClass}`}>
              <div
                className={`h-auto rounded-lg shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding outline-none text-current ${contentClass}`}
              >
                {modalIcon && (
                  <div className="flex justify-center">{modalIcon}</div>
                )}
                {title && (
                  <div className="flex justify-center p-5 border-solid border-gray-300 rounded">
                    <h3 className="md:text-3xl text-lg font=semibold">
                      {title}
                    </h3>
                  </div>
                )}

                <div
                  className={`scrollbar-hide ${childrenContainer}`}
                >
                  {children}
                </div>
                {showCloseButton && (
                  <img
                    className="w-5 absolute right-2 -top-10 cursor-pointer"
                    src={CloseIcon}
                    alt="close icon"
                    onClick={() => setIsOpen(null)}
                    style={{ filter: "invert(100%)" }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
