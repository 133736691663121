import React from 'react'
import Select from 'react-select'
import { customStyles } from '../../utils/selectStyles'
interface IPageCount {
    options: { label: string; value: any }[]
    handleEntriesCount: any
    entriesCount: number | string
    type?: boolean
}

export function PageCount({
    options,
    handleEntriesCount,
    entriesCount,
    type,
}: IPageCount): JSX.Element {
    return (
        <div className="flex gap-4 items-center">
            {!type ? (
                <div className="text-xs">Showing:</div>

            ): ""}

            <Select
                options={options}
                className="w-[120px]"
                onChange={(input: any) => handleEntriesCount(input.value)}
                // styles={customStyles}
                defaultValue={{
                    label: `${entriesCount} ${!type ? 'Entries' : ''}`,
                    value: `${entriesCount}`,
                }}
            />
        </div>
    )
}
