import React, { FC, ReactNode } from 'react';

interface AdminContentProps {
    children: ReactNode;
}

const AdminContent: FC<AdminContentProps> = ({ children }) => {
    return (
        <main className="absolute w-full bg-[#F1F2F3]">
            {children}
        </main>
    )
}

export default AdminContent;
