import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAllactiveCategories } from '../utils/services/cmsPortal'


export default function useAllActiveCategories() {
    const [categories, setCategories] = useState([])
    const [payload, setPayload] = useState({
        pageSize: 100,
        from: 1,
        parameter: {
            query: ""
        }
    })

    const { refetch } = useQuery(["GetAllActiveCategories"], () => getAllactiveCategories(payload), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setCategories(response?.data?.data?.object)
        },        
    })

    const getAllActiveCategories = () => {
        refetch();
    }

    return { categories, getAllActiveCategories, }
}
