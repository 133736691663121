import React, { ReactElement } from 'react'
import moment from 'moment'

interface IDrawer {
    isOpen: boolean
    setIsOpen: any
    children: React.ReactNode
}

export function SideModal({
    isOpen,
    setIsOpen,
    children,
}: IDrawer): ReactElement {
    return (
        <div
            className={
                ' fixed  overflow-hidden z-40 bg-gray-900 bg-opacity-5 inset-0  transform ease-in-out ' +
                (isOpen
                    ? 'duration-500 translate-x-0'
                    : ' transition-all delay-500 opacity-0 translate-x-full  ')
            }
        >
            <div
                className={
                    ' w-screen overflow-hidden max-w-lg right-0 absolute bg-white h-full z-50  delay-400 duration-500 ease-in-out transition-all transform  ' +
                    (isOpen ? ' translate-x-0 shadow-lg rounded-lg  ' : ' translate-x-full ')
                }
            >
                {children}
            </div>
            <div
                className="w-screen h-full cursor-pointer "
                onClick={() => {
                    setIsOpen(false)
                }}
            ></div>
        </div>
    )
}
