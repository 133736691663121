import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { FallbackBg } from "./components/FallbackBg/FallBackBg";
import AppRoutes from './routes/appRoutes';

// import './App.css';
import Login from './pages/Login/login';



function App() {
  return (
    // <Login/>
    <BrowserRouter>
    <Suspense fallback={<FallbackBg />}>
      <AppRoutes />
    </Suspense>
  </BrowserRouter>

  );
}

export default App;
