import moment from "moment";
import React from "react";
import { useEffect, useState, useRef } from "react";
import Select, { Props as SelectProps } from "react-select";
import { customStyles } from "../../utils/selectStyles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { ButtonPrimary } from "../../components/UIkit/ButtonPrimary/Button";
import useAllActiveProducts from "../../hooks/useAllActiveProducts";
import useAllActiveCategories from "../../hooks/useAllActiveCategories";
import { AppModal } from "../../components/UIkit/AppModal/AppModal";
import useAllCategoriesProduct from "../../hooks/useAllCategoriesByProduct";
import { fectchCategoriesByProductId } from "../../utils/services/cmsPortal";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { addFaq } from "../../utils/services/cmsPortal";
import successIcon from "../../assets/img/successIcon.svg";
import { Spinner } from "../../components/UIkit/Spinner/Spinner";
import CustomMenu from "../../components/CustomMenu/CustomMenu";
import { addCategory } from "../../utils/services/cmsPortal";
import { SideModal } from "../../components/UIkit/AppModal/SideModal";
import backIcon from "../../assets/img/fi-rr-arrow-left.svg";
interface CardDataType {
  // cardData: {
  //     userName: string
  //     email: string
  //     ipAddress: string
  //     description: string
  //     createdDate: string
  //     time?: string
  //     phoneNo?: string
  //     location?: string

  // }
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const schema = yup.object().shape({
  selectedProduct: yup.object().shape({
    value: yup.string().required("Product is required"),
    label: yup.string().required(),
  }),
  category: yup.object().shape({
    value: yup.string().required("faq category is required"),
    label: yup.string().required(),
  }),

  // content: yup.string().required('Faq main content is required'),
  title: yup.string().required("Title is required"),
});

const AddFaq = ({ setIsOpen, isOpen }: CardDataType) => {
  const { products, getAllActiveProducts } = useAllActiveProducts();
  const { categories, getAllActiveCategories } = useAllActiveCategories();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [content, setContent] = useState("");
  const [showSuccessModal, setSuccessModal] = useState<any>();
  const customMenuRef = useRef<HTMLDivElement>(null);
  // const [addCategoryLoading, setAddCategoryLoading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isAddCategoryClicked, setIsAddCategoryClicked] = useState(false);

  const handleAddCategory = () => {
    setIsModalOpen(true);
    setIsAddCategoryClicked(true);
  };

  const handleSaveCategory = () => {
    submitCategories({
      selectedProduct: selectedProduct,
      categoryName: newCategoryName,
    });
    // setIsModalOpen(false);
    setNewCategoryName("");
    setIsAddCategoryClicked(false);
  };

  const handleCancelAddCategory = () => {
    setIsModalOpen(false);
    setNewCategoryName("");
    setIsAddCategoryClicked(false);
  };

  const openPreviewModal = () => {
    setIsPreviewOpen(true);
  };

  const closePreviewModal = () => {
    setIsPreviewOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const currentValues = watch();

  const { mutateAsync: addfaqs, data , isLoading: isAddFaqLoading } = useMutation(addFaq, {
    onSuccess: ({ data }) => {
      setSuccessModal(true);
      toast.success("Faq created successfully");
      // navigate('/settings/asset')
      // reset()
      // reset(defaultValues)
    },
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        //@ts-ignore
        toast.error(error?.response?.data?.errorMessageList[0]);
        return;
      }
      toast.error("an error occurred");
    },
  });

  const { mutateAsync: addcategories , isLoading: isAddCategoryLoading} = useMutation(addCategory, {
    onSuccess: async ({ data }) => {
      // setSuccessModal(true);
      setIsModalOpen(false);
      toast.success("Category added successfully");
      // setIsModalOpen(false);
      await refetch();

    },
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        //@ts-ignore
        toast.error(error?.response?.data?.errorMessageList[0]);
        return;
      }
      toast.error("an error occurred");
    },
  });

  // const submitCategories = async (data: any) => {
  //   try {
  //     // setAddCategoryLoading(true);
  //     await addCategory({
  //       productId: data.selectedProduct.value,
  //       categoryName: data.categoryName,
  //     });
  //   } catch (error) {
  //     // Handle error
  //   }
  // };

  const submitCategories = async (data: any) => {
    addcategories({
      productId: data.selectedProduct.value,
      categoryName: data.categoryName,
    });
  };

  const onSubmit = (status: number) => {
    const data = getValues();
    console.log(data, "efi");
    addfaqs({
      // selectedProduct: parseInt(data.selectedProduct.value),
      categoryId: data.category.value,
      content: content,
      title: data.title,
      status: status,
    });
  };

  const payload = {
    pageSize: 100,
    pageNumber: 1,
    parameter: {
      //@ts-ignore
      productId: selectedProduct?.value,
    },
  };

  const {
    isError,
    data: categoriesProduct,
    error,
    refetch,
  } = useQuery(
    ["FectchCategoriesByProductId"],
    () => fectchCategoriesByProductId(payload),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {
        console.log("res", res);
      },
      onError: (error: AxiosError) => {
        // if (error.response?.data?) {
        //     toast.error(error.response.data.errors[0])
        //     return
        // }
        toast.error("an error occurred");
      },
    }
  );
  const categoriesData = categoriesProduct?.data?.data?.object;

  const handleFormatChange = (value: any) => {
    setContent(value);
    console.log(value, "dvalue");
  };

  const handleProductChange = (selectedOption: any) => {
    setSelectedProduct(selectedOption);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const editorStyle = {
    height: "150px",
  };

  useEffect(() => {
    if (selectedProduct) {
      const updatedPayload = {
        pageSize: 100,
        pageNumber: 1,
        parameter: {
          //@ts-ignore
          productId: selectedProduct?.value,
        },
      };
      //@ts-ignore
      refetch(updatedPayload); // Trigger API call with updated payload
      console.log(updatedPayload, "what");
    }
  }, [selectedProduct]);
  useEffect(() => {
    getAllActiveCategories();
    getAllActiveProducts();
  }, []);

  console.log(currentValues, "awa");
  console.log(selectedProduct, "awa2");

  return (
    <div>
      <SideModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="relative p-4 w-screen max-w-lg pb-10 flex flex-col overflow-y-scroll  h-full">
          <div className="flex justify-between p-4 items-center">
            <p className="font-bold font-Inter text-lg text-[#24292E]">
              Add Faq
            </p>
            <span
              className="text-[#24292E] text-lg cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              x
            </span>
          </div>

          <form
            //@ts-ignore
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-4 mt-12">
              <div className="mb-6">
                <label className="text-xs font-light">Product</label>
                <Select
                  placeholder="Select Product"
                  options={products?.map(
                    (ele: { id: any; productName: any }) => {
                      return {
                        value: ele.id,
                        label: ele.productName,
                      };
                    }
                  )}
                  onChange={(selectedOption) =>
                    handleProductChange(selectedOption)
                  }
                  value={selectedProduct}
                  // onChange={(newValue: any) =>
                  //     setValue('product', newValue, {
                  //         shouldValidate: true,
                  //     })
                  // }
                  styles={customStyles}
                />
                <p className="text-xs text-red-600">
                  {typeof errors?.selectedProduct?.message === "string"
                    ? errors?.selectedProduct?.message
                    : ""}
                </p>
              </div>

              <div className="mb-6 relative">
                <label className="text-xs font-light">Faq Category</label>
                <Select
                  placeholder="Select Faq Category"
                  options={categoriesData?.map(
                    (ele: { id: any; categoryName: any }) => {
                      return {
                        value: ele.id,
                        label: ele.categoryName,
                      };
                    }
                  )}
                  components={{
                    Menu: (props: SelectProps<any>) => (
                      //@ts-ignore
                      <CustomMenu
                        {...props}
                        handleAddCategory={handleAddCategory}
                        isAddCategoryClicked={isAddCategoryClicked} // Pass the state down
                        innerRef={customMenuRef}
                      />
                    ),
                  }}
                  onChange={(newValue: any) =>
                    setValue("category", newValue, {
                      shouldValidate: true,
                    })
                  }
                  styles={customStyles}
                />
                <span className="font-light">
                  Note: All Faqs are by default classified as "General"
                </span>

                <p className="text-xs text-red-600">
                  {typeof errors?.category?.message === "string"
                    ? errors?.category?.message
                    : ""}
                </p>
              </div>
              <div className="mb-6">
                <label className="text-xs font-light">Title</label>
                <div className="flex gap-2 rounded-lg border border-gray h-11 pr-4 pl-1">
                  <input
                    className="w-full py-4 focus:ring-0"
                    type="text"
                    autoComplete="new"
                    onFocus={(e) => e.target.removeAttribute("readonly")}
                    readOnly
                    {...register("title")}
                  />
                  <p className="text-xs text-red-600">
                    {typeof errors?.title?.message === "string"
                      ? errors?.title?.message
                      : ""}
                  </p>
                </div>
              </div>
            </div>

            <div className="px-4 mb-14">
              <p className="font-light text-xs text-[#24292E] ">
                Faq Main Content
              </p>
              <ReactQuill
                onChange={handleFormatChange}
                style={editorStyle}
                value={content}
                // {...register("content")} // Register the "content" field
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline"],
                    ["image", "code-block"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                  ],
                }}
                theme="snow"
              />{" "}
              <p className="text-xs text-red-600">
                {typeof errors?.content?.message === "string"
                  ? errors?.content?.message
                  : ""}
              </p>
            </div>

            <div className="flex items-center justify-between mt-12">
              <div className="flex items-center">
                <span
                  className="underline font-light tex-xs"
                  onClick={() => onSubmit(1)}
                >
                  Save as Draft
                </span>
              </div>
              <div className="ml-4">
                <button
                  className="border border-gray-500 py-1 px-3 rounded w-40"
                  onClick={openPreviewModal}
                >
                  Preview
                </button>
              </div>
              <div className="ml-4">
                {/* <ButtonPrimary
              label="Publish"
              // handleClick={handleClickOpen}
            /> */}
                <button
                  className="bg-blue-500 text-white py-1 px-3 rounded w-40"
                  onClick={() => onSubmit(2)}
                  disabled={isAddFaqLoading}
                >
                  {isAddFaqLoading ? <Spinner /> : "Publish"}
                </button>
              </div>
            </div>
          </form>

          <SideModal
            isOpen={isPreviewOpen}
            setIsOpen={() => setIsPreviewOpen(false)}
          >
            {/* <AppModal
              isOpen={isPreviewOpen}
              setIsOpen={closePreviewModal}
              title=""
              showCloseButton
            > */}
            <div className="relative p-4 w-screen max-w-lg pb-10 flex flex-col overflow-y-scroll  h-full">
              {/* <div className="font-bold text-lg">
                  <>
                    <span className="mr-2" onClick={()=>setIsPreviewOpen(false)}>
                      <img src={backIcon} alt="back" />{" "}
                    </span>
                   
                  </>
                </div> */}
              <div className="flex items-center">
                <span
                  className="cursor-pointer font-bold text-lg flex items-center"
                  onClick={() => setIsPreviewOpen(false)}
                >
                  <img src={backIcon} alt="back" className="mr-2" />
                  Preview
                </span>
              </div>

              <div className="mb-6 mt-12">
                <input
                  type="text"
                  // @ts-ignore
                  value={selectedProduct?.label}
                  disabled
                  className="border border-gray-300 p-2 w-32 rounded"
                />
                <div className="mb-6 mt-12">
                  <label className="text-xs font-light">Faq Category</label>
                  <div className="mt-1">{currentValues?.category?.label}</div>
                </div>
                <div className="mb-6 mt-12">
                  <label className="text-xs font-light">Faq Title</label>
                  <div className="mt-1">{currentValues.title}</div>
                </div>
                <div className="mb-6 mt-12">
                  <label className="text-xs font-light">Content</label>
                  <div className="mt-1">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                </div>
              </div>
            </div>
            {/* </AppModal> */}
          </SideModal>
        </div>
      </SideModal>
      {isModalOpen && (
        <AppModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          title=""
          // showCloseButton
        >
          <div className="flex flex-col items-center">
            <input
              placeholder="Category Name"
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              className="border border-gray-100 p-2 mb-4 w-full max-w-sm"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={handleCancelAddCategory}
                className="border border-gray-500 py-1 px-3 rounded w-24"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveCategory}
                className="bg-blue-500 text-white py-1 px-3 rounded w-24"
                disabled={isAddCategoryLoading}
              >
                {isAddCategoryLoading ? <Spinner /> : "Save"}
              </button>
            </div>
          </div>
        </AppModal>
      )}
      {showSuccessModal && (
        <AppModal
          isOpen={showSuccessModal}
          setIsOpen={setSuccessModal}
          title="Faq successfully Published"
          showCloseButton
          modalIcon={<img src={successIcon} alt="delete icon" />}
        >
          <div className="flex flex-col items-center h-28">
            <p className="text-xs">
              {"Your faq has been successfully published"}
            </p>
            <div className="flex justify-center mt-6">
              <button
                className="bg-blue-500 text-white border rounded-md w-[80px] py-3"
                onClick={reloadPage}
              >
                Done
              </button>
            </div>
          </div>
        </AppModal>
      )}
    </div>
  );
};

export default AddFaq;
