import moment from "moment";
import React, { ReactElement, useState, useRef, useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../utils/selectStyles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { ButtonPrimary } from "../../components/UIkit/ButtonPrimary/Button";
import useAllActiveProducts from "../../hooks/useAllActiveProducts";
import useAllActiveCategories from "../../hooks/useAllActiveCategories";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { updateFaq } from "../../utils/services/cmsPortal";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { AppModal } from "../../components/UIkit/AppModal/AppModal";
import successIcon from "../../assets/img/successIcon.svg";
import { useQuery } from "@tanstack/react-query";
import { fectchCategoriesByProductId } from "../../utils/services/cmsPortal";
import { SideModal } from "../../components/UIkit/AppModal/SideModal";
import backIcon from "../../assets/img/fi-rr-arrow-left.svg";
import { Spinner } from "../../components/UIkit/Spinner/Spinner";
// import ReactHtmlParser from "react-html-parser";

const schema = yup.object().shape({
  title: yup.object().shape({
    value: yup.string().required("title is required"),
    label: yup.string().required(),
  }),
  category: yup.object().shape({
    value: yup.string().required("category type is required"),
    label: yup.string().required(),
  }),
  product: yup.object().shape({
    value: yup.string().required("product name is required"),
    label: yup.string().required(),
  }),
  // mainContent: yup.string().required("main content is required"),
});

interface CardDataType {
  cardData: {
    title: string;
    categoryName: string;
    productName: string;
    datePosted: string;
    createdDate: string;
    status?: string;
    content?: string;
    categoryId?: string;
    productId?: string;
    id?: string;
  };
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditFaq = ({ cardData, setIsOpen, isOpen }: CardDataType) => {
  console.log(cardData, "card");
  // const { categoryName, categoryId, id, content, productName, productId } = cardData;
  const { products, getAllActiveProducts } = useAllActiveProducts();
  const { categories, getAllActiveCategories } = useAllActiveCategories();
  const [showSuccessModal, setSuccessModal] = useState<any>();
  const [content, setContent] = useState(cardData.content);
  const [selectedProduct, setSelectedProduct] = useState({
    value: cardData.productId,
    label: cardData.productName,
  });
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const location = useLocation();

  // const { state }: { state: any } = location

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   id: cardData.id,
    //   title: cardData.title,
    //   content: cardData.content,
    //   category: {
    //     value: cardData.categoryId,
    //     label: cardData.categoryName,
    //   },
    //   selectedProduct: {
    //     value: cardData.productId,
    //     label: cardData.productName,
    //   },
    // },
  });

  const handleFormatChange = (value: any) => {
    setValue("content", value); 
  };
  // useEffect(() => {
  //   if (cardData) {
  //     console.log(cardData, "cardDooo"); // Add this line
  //     setValue("title", cardData?.title, {
  //       shouldValidate: true,
  //     });
  //     setValue("content", cardData?.content, {
  //       shouldValidate: true,
  //     });

  //     if (cardData?.categoryId && cardData?.categoryName) {
  //       setValue(
  //         "category",

  //         {
  //           value: cardData?.categoryId,
  //           label: cardData?.categoryName,
  //         },
  //         {
  //           shouldValidate: true,
  //         }
  //       );
  //     }
  //     //@ts-ignore
  //     if (cardData?.productId && cardData?.productName) {
  //       setValue(
  //         "selectedProduct",
  //         //@ts-ignore

  //         {
  //           //@ts-ignore

  //           value: cardData?.productId,
  //           label: cardData?.productName,
  //         },
  //         {
  //           shouldValidate: true,
  //         }
  //       );
  //     }
  //   }
  // }, [cardData]);
  // console.log(cardData, "state");

  const reloadPage = () => {
    window.location.reload();
  };

  const payload = {
    pageSize: 100,
    pageNumber: 1,
    parameter: {
      productId: selectedProduct.value,
    },
  };

  const {
    // isLoading,
    isError,
    data: categoriesProduct,
    error,
    refetch,
  } = useQuery(
    ["FectchCategoriesByProductId"],
    () => fectchCategoriesByProductId(payload),
    {
      enabled: true,
      retry: false,
      onSuccess: (res) => {
        console.log("res", res);
      },
      onError: (error: AxiosError) => {
        // if (error.response?.data?) {
        //     toast.error(error.response.data.errors[0])
        //     return
        // }
        toast.error("an error occurred");
      },
    }
  );

  const categoriesData = categoriesProduct?.data?.data?.object;

  const currentValues = watch();

  const { mutateAsync, data, isLoading } = useMutation(updateFaq, {
    onSuccess: ({ data }) => {
      setSuccessModal(true);
      toast.success("Faq updated successfully");
      // navigate('/settings/asset')
      // reset()
      // reset(defaultValues)
    },
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        //@ts-ignore
        toast.error(error?.response?.data?.errorMessageList[0]);
        return;
      }
      toast.error("an error occurred");
    },
  });

  const onSubmit = () => {
    const data = getValues();
    console.log(data, "efi");
    mutateAsync({
      id: cardData.id,
      categoryId: data.category.value,
      content: data.content,
      title: data.title,
      status: 2,
    });
  };

  const openPreviewModal = () => {
    setIsPreviewOpen(true);
  };


  const handleProductChange = (selectedOption: any) => {
    setSelectedProduct(selectedOption);
  };

  useEffect(() => {
    if (selectedProduct) {
      const updatedPayload = {
        pageSize: 100,
        pageNumber: 1,
        parameter: {
          //@ts-ignore
          productId: selectedProduct?.value,
        },
      };
      //@ts-ignore
      refetch(updatedPayload); // Trigger API call with updated payload
      console.log(updatedPayload, "what");

    }
  }, [selectedProduct]);
  useEffect(() => {
    if (isOpen) {
      setValue("selectedProduct", {
        value: cardData.productId,
        label: cardData.productName,
      });
      setValue("category", {
        value: cardData.categoryId,
        label: cardData.categoryName,
      });
      setValue("title", cardData.title);
      setValue("content", cardData.content);
    }
  }, [isOpen, cardData, setValue]);

  useEffect(() => {
    if (isOpen) {
      console.log(cardData, "which");
    }
  }, [isOpen, cardData]);

  const editorStyle = {
    height: "150px",
  };
  return (
    <div>
      <SideModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="relative p-4 w-screen max-w-lg pb-10 flex flex-col overflow-y-scroll  h-full">
          <div className="flex justify-between p-4 items-center">
            <p className="font-bold font-Inter text-lg text-[#24292E]">
              Edit Faq
            </p>
            <span
              className="text-[#24292E] text-lg cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              x
            </span>
          </div>

          <form
            //@ts-ignore
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-4 mt-12">
              <div className="mb-6">
                <label className="text-xs font-light">Product</label>
                <Select
                  placeholder="Select Product"
                  options={products?.map(
                    (ele: { id: any; productName: any }) => {
                      return {
                        value: ele.id,
                        label: ele.productName,
                      };
                    }
                  )}
                  // onChange={(newValue: any) => {
                  //   setSelectedProduct(newValue); // Update the selected product
                  //   setValue("selectedProduct", newValue, {
                  //     shouldValidate: true,
                  //   });
                  // }}
                  onChange={(selectedOption) =>
                    handleProductChange(selectedOption)
                  }
                  defaultValue={{
                    value: cardData.productId,
                    label: cardData.productName,
                  }}
                  styles={customStyles}
                />
              </div>
              <div className="mb-6">
                <label className="text-xs font-light">Faq Category</label>
                <Select
                  placeholder="Select Faq Category"
                  options={categoriesData?.map(
                    (ele: { id: any; categoryName: any }) => {
                      return {
                        value: ele.id,
                        label: ele.categoryName,
                      };
                    }
                  )}
                  onChange={(newValue: any) =>
                    setValue("category", newValue, {
                      shouldValidate: true,
                    })
                  }
                  
                  defaultValue={{
                    value: cardData.categoryId,
                    label: cardData.categoryName,
                  }}
                  styles={customStyles}
                />
                <span className="font-thin">
                  Note: All Faqs are by default classified as "General"
                </span>
              </div>
              <div className="mb-6">
                <label className="text-xs font-light">Title</label>
                <div className="flex gap-2 rounded-lg border border-gray h-11 pr-4 pl-1">
                  <input
                    className="w-full py-4 focus:ring-0"
                    type="text"
                    // name="title"
                    autoComplete="new"
                    {...register("title")}
                    value={watch("title")}
                  />
                </div>
              </div>
            </div>

            <div className="px-4 mb-14">
              <p className="font-light text-xs text-[#24292E] ">
                Faq Main Content
              </p>
              <ReactQuill
               value={watch("content")}                
               onChange={handleFormatChange}
                style={editorStyle}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline"],
                    ["image", "code-block"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                  ],
                }}
                theme="snow"
              />{" "}
            </div>

            <div className="flex items-center justify-between mt-12">
              <div className="ml-[30px]">
                <button
                  className="border border-gray-500 py-1 px-3 rounded w-40"
                  onClick={openPreviewModal}
                >
                  Preview
                </button>
              </div>
              <div className="mr-16">
                {/* <ButtonPrimary
              label="Publish"
              // handleClick={handleClickOpen}
            /> */}
                <button
                  className="bg-blue-500 text-white py-1 px-3 rounded w-40"
                  onClick={onSubmit}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          </form>
          <SideModal
            isOpen={isPreviewOpen}
            setIsOpen={() => setIsPreviewOpen(false)}
          >
            {/* <AppModal
              isOpen={isPreviewOpen}
              setIsOpen={closePreviewModal}
              title=""
              showCloseButton
            > */}
            <div className="relative p-4 w-screen max-w-lg pb-10 flex flex-col overflow-y-scroll  h-full">
              <div className="flex items-center">
                <span
                  className="cursor-pointer font-bold text-lg flex items-center"
                  onClick={() => setIsPreviewOpen(false)}
                >
                  <img src={backIcon} alt="back" className="mr-2" />
                  Preview
                </span>
              </div>

              <div className="mb-6 mt-12">
                <input
                  type="text"
                  //@ts-ignore
                  value={currentValues?.selectedProduct?.label}
                  disabled
                  className="border border-gray-300 p-2 w-32 rounded"
                />
                <div className="mb-6 mt-12">
                  <label className="text-xs font-light">Faq Category</label>
                  <div className="mt-1">{currentValues?.category?.label}</div>
                </div>
                <div className="mb-6 mt-12">
                  <label className="text-xs font-light">Faq Title</label>
                  <div className="mt-1">{currentValues.title}</div>
                </div>
                <div className="mb-6 mt-12">
                  <label className="text-xs font-light">Content</label>
                  <div className="mt-1">
                    {/* <div>{ReactHtmlParser(currentValues.content)}</div> */}
                    <div dangerouslySetInnerHTML={{ __html: currentValues.content}} />
                    {/* {currentValues.content} */}
                  </div>
                </div>
              </div>
            </div>
            {/* </AppModal> */}
          </SideModal>
        </div>
      </SideModal>
      {showSuccessModal && (
        <AppModal
          isOpen={showSuccessModal}
          setIsOpen={setSuccessModal}
          title="Faq successfully Updated"
          showCloseButton
          modalIcon={<img src={successIcon} alt="delete icon" />}
        >
          <div className="flex flex-col items-center h-28">
            <p className="text-xs">
              {"Your faq has been successfully updated"}
            </p>
            <div className="flex justify-center mt-6">
              <button
                className="bg-blue-500 text-white border rounded-md w-[80px] py-3"
                onClick={reloadPage}
              >
                Done
              </button>
            </div>
          </div>
        </AppModal>
      )}
    </div>
  );
};

export default EditFaq;
