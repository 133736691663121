import { FC } from "react";
import { Outlet } from "react-router-dom";
// import useTitle from "../../../hooks/useTitle";
// import { setShowDropdown } from "../../../redux/features/ui-slice";
// import { useAppDispatch, useAppSelector } from "../../../redux/store/hooks";
import AdminContent from "./Content";
import { AdminHeader } from "./Header/";

interface Props {
    breadcrumbs?: any
    children?: any

}
const GenericLayout: FC<Props> = ({ children, breadcrumbs }) => {
  // const dispatch = useAppDispatch();
  // const { showDropDown, toggleSidebar, leaveOpen } = useAppSelector(
  //   (state: any) => state.ui
  // );
  // const { title, hideBottomBorder } = useTitle();
  // const handleLayoutClick = () => {
  //   if (showDropDown && leaveOpen) {
  //     dispatch(setShowDropdown(null));
  //   }
//     if (toggleSidebar) {
//       // setToggleSidebar(false);
//     }
//   };

  return (
    <main
      className="flex relative h-screen bg-white w-screen overflow-x-hidden"
      // onClick={handleLayoutClick}
    >
       <AdminContent>
                <AdminHeader breadcrumbs={breadcrumbs} />
                {children}
                {/* <p>what</p> */}
                {/* <Outlet /> */}
            </AdminContent>
    </main>
  );
};

export default GenericLayout;
