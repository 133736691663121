import { useState, useContext, FC } from "react";
// import { UiContext } from '../../../context/uiContext'
import { Link, useLocation } from "react-router-dom";
import avatar from "../../../assets/img/avatar.png";
import question from "../../../assets/img/question.svg";
import notification from "../../../assets/img/notification.svg";
// import hamburger from '../../../assets/img/hamburger.svg'
import arrowDown from "../../../assets/img/arrowDown.svg";
import { ProfileDropdown } from "../../ProfileDropdown";
interface Props {
  breadcrumbs?: any[];
}
export const AdminHeader: FC<Props> = ({ breadcrumbs }) => {
  const storageData: any = localStorage.getItem("cmsUser");
  const currentUser = JSON.parse(storageData);
  const ddName = currentUser?.data?.fullName && currentUser?.data?.fullName.split(" ");
  const curUserName = ddName && `${ddName[0]} ${ddName[1].substr(0, 1)}`;
  const curRole = currentUser?.data?.userRole;
  const location = useLocation();
  // const { toggleSidebar, setToggleSidebar } = useContext(UiContext)
  // const [searchPhrase, setSearchPhrase] = useState('')
  const [showModal, setShowModal] = useState(false);

  // const handleSidebar = () => {
  //     setToggleSidebar(!toggleSidebar)
  // }
  // const handleSearch = (input: string) => {
  //     setSearchPhrase(input)
  // }
  const paths = location.pathname.split("/");
console.log(storageData, "userData");
console.log(currentUser, "userCurrent");


  return (
    <header className="h-[80pxpx] lg:h-[80px] bg-[#F6F8FA] sticky top-0 flex justify-between z-20">
      <div className="flex flex-col w-full justify-center">
        <div className="w-full flex items-center justify-end md:justify-between py-4">
          <div className="pl-6 md:block">
            <h2
              className="font-semibold text-2xl	 leading-6"
              style={{
                background:
                  "-webkit-linear-gradient(193.6deg, #247AFF 34.96%, #0C2750 91.03%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              ARAP CMS PORTAL
            </h2>
          </div>
          <div className="flex gap-3 items-center pr-4 relative">
            {/* <div className="hidden md:block">
                            <img src={question} alt="question" />
                        </div> */}
            {/* <div className="hidden md:block">
                            <img src={notification} alt="notification" />
                        </div> */}
            {/* <span className="hidden md:block Poppins text-sm capitalize">
              {curUserName}
              Salami O.
            </span> */}
            <div className="flex items-center space-x-2">
             
              <div>
                <p className="font-semibold">{curUserName}</p>
                <p className="text-sm text-gray-500">{curRole}</p>
              </div>
            </div>
            <div
              className="flex gap-1 cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={avatar} className="w-[25px] h-[25px] border-none" />
              <img src={arrowDown} alt="arrow down" />
            </div>
            <ProfileDropdown isOpen={showModal} setIsOpen={setShowModal} />
          </div>
        </div>
      </div>
    </header>
  );
};
