import React from "react";
import Select, { Props as SelectProps, MenuProps } from "react-select";
import AddIcon from "../../assets/img/fi-rr-plus-small.svg";

interface CustomMenuProps {
  innerRef: React.RefObject<HTMLDivElement>;
  innerProps: React.HTMLAttributes<HTMLDivElement>;
  isDisabled: boolean;
  children: React.ReactNode;
  handleAddCategory: () => void;
  isAddCategoryClicked: boolean; // Add this line

}

const CustomMenu: React.FC<CustomMenuProps> = ({
  innerRef,
  innerProps,
  isDisabled,
  children,
  handleAddCategory,
  isAddCategoryClicked,
}) =>
  !isDisabled ? (
    <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
      {children}
      {!isAddCategoryClicked && (
       <button
        className="bg-blue-500 text-white border rounded-lg w-full md:w-[450px] py-3"
        onClick={() => {
          handleAddCategory();
        }}
      >
        Add Category
      </button>
      )}
      {/* {!isAddCategoryClicked && (
        <button onClick={handleAddCategory}>Add Category</button>
      )} */}
    </div>
  ) : null;

export default CustomMenu;
