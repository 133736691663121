import { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import GenericLayout from "../components/GenericLayout/index";
import { AnimatePresence } from "framer-motion";
import ARAPCmsPortal from "../pages/cmsPortal/cmsPortal";

const Login = lazy(() => import("../pages/Login/login"));


const AppRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/arap-cms" element={<ARAPCmsPortal />} />

        {/* <Route path="/admin" element={<GenericLayout />}>
          <Route path="/arap-cms" element={<ARAPCmsPortal />} />
        </Route> */}
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
