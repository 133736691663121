import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "../../components/UIkit/DropDown/DropDown";
import ellipseIcon from "../../assets/img/ellipse.svg";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { AppModal } from "../../components/UIkit/AppModal/AppModal";
import moment from "moment";
import { ButtonOutlined } from "../../components/UIkit/ButtonOutlined/ButtonOutlined";
import editIcon from "../../assets/img/fi-rr-edit.svg";
import deleteIcon from "../../assets/img/fi-rr-trash.svg";
import { SideModal } from "../../components/UIkit/AppModal/SideModal";
import AddFaq from "./cmsPortalModal";
import EditFaq from "./editFaq";
import trashIcon from "../../assets/img/deleteIcon.svg";
import { ButtonPrimary } from "../../components/UIkit/ButtonPrimary/Button";
import { deleteFaq } from "../../utils/services/cmsPortal";
import successIcon from "../../assets/img/successIcon.svg";
import { Spinner } from "../../components/UIkit/Spinner/Spinner";
import { log } from "console";

export const CmsTable: FC<{
  apiData: any;
  refetch?: any;
}> = ({ apiData, refetch }) => {
  const [showEditModal, setShowEditModal] = useState<any>();
  const [showDeleteModal, setShowDeleteModal] = useState<any>();
  const [showSuccessModal, setSuccessModal] = useState<any>();
  const [selectedFaq, setSelectedFaq] = useState<number | null>(null);


  const { mutateAsync: onDelete, isLoading } = useMutation(deleteFaq, {
    onSuccess: ({ data }) => {
      setSuccessModal(true);
      toast.success(data.description);
      // refetch();
    },
    onError: (error: AxiosError) => {
      setSuccessModal(false);
      toast.error(JSON.stringify(error.response?.data));
    },
  });

  const reloadPage = () => {
    window.location.reload();
  };

  const handleDelete = () => {
    onDelete({ id: showDeleteModal });
  };

  const handleEditClick = (index: number) => {
    setSelectedFaq(index);
    setShowEditModal(true);
    console.log(selectedFaq, "faq");
    
  };
  
  const handleDeleteClick = (index: number) => {
    setSelectedFaq(index);
    setShowDeleteModal(apiData[index].id);
  };

  const handleCloseModals = () => {
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (selectedFaq !== null) {
  //     setShowEditModal(true);
  //   }
  // }, [selectedFaq]);
  // console.log(selectedFaq, "selected");
  
  {
    return (
      <table className="w-full" style={{ fontSize: "14px", cursor: "pointer" }}>
        <thead className={"m-6 bg-slate-50 h-10"}>
          <tr className="">
            <th className="text-base text-[#677684] font-normal">#</th>
            <th className="text-base text-[#677684] font-normal">
              Faq Category
            </th>
            <th className="text-base text-[#677684] font-normal">Title</th>
            <th className="text-base text-[#677684] font-normal">Product</th>
            <th className="text-base text-[#677684] font-normal">
              Date Posted
            </th>
            <th className="text-base text-[#677684] font-normal">Status</th>
            <th className="text-base text-[#677684] font-normal">Action</th>
          </tr>
        </thead>
        <tbody>
          {apiData.map((item: any, index: number) => {
            return (
              <tr className="border-b bg-white border-slate-100" key={index}>
                <td className="text-xs text-[#677684] px-2 py-3 font-normal">
                  {index + 1}
                </td>
                <th className="text-xs text-[#677684] px-2 py-3 font-normal">
                  {item.categoryName}
                </th>
                <th className="text-xs text-[#677684] px-2 py-3 font-normal">
                  {item.title}
                </th>
                <th className="text-xs text-[#677684] px-2 py-3 font-normal">
                  {item.productName}
                </th>
                <th className="text-xs text-[#677684] px-2 py-3 font-normal">
                  {moment(
                    item.dateUpdated !== null
                      ? item.dateUpdated
                      : item.dateCreated
                  ).format("YYYY-MM-DD")}
                  {/* {moment(item.dateCreated).format("YYYY-MM-DD")} */}
                </th>

                <th className="text-xs text-[#677684] px-4 py-6 font-normal">
                  {item.status === 2 ? (
                    <span className="text-[#1D8627] bg-[#E9FCF3] rounded py-1 px-3 font-light">
                      Published
                    </span>
                  ) : item.status === 1 ? (
                    <span className="text-[#E8B606] bg-[#FCF8E9] rounded py-1 px-3 font-light">
                      Draft
                    </span>
                  ) : (
                    ""
                  )}
                </th>
                <th className="ml-10">
                  {/* {item.status === 2 ? ( */}
                    <div className="flex justify-center gap-4">
                      <ButtonOutlined
                        label="Edit"
                        handleClick={() => handleEditClick(index)}
                        icon={
                          <img
                            src={editIcon}
                            alt="edit"
                            width="15"
                            height="15"
                          />
                        }
                      />

                      <ButtonOutlined
                        label="Delete"
                        handleClick={() => handleDeleteClick(index)}
                        icon={
                          <img
                            src={deleteIcon}
                            alt="edit"
                            width="15"
                            height="15"
                          />
                        }
                      />
                    </div>
                  {/* ) : (
                    ""
                  )} */}
                </th>
                {/* <SideModal isOpen={showEditModal} setIsOpen={setShowEditModal}> */}
                  {selectedFaq !== null && (
                    <EditFaq 
                    isOpen={showEditModal}
                      setIsOpen={setShowEditModal}
                      cardData={{
                        ...apiData[selectedFaq],
                      }}
                    />
                  )}
                {/* </SideModal> */}
              </tr>
            );
          })}
        </tbody>
        

        <AppModal
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          title="Delete Faq"
          showCloseButton
          // childrenContainer="scrollbar-hide"
          modalIcon={<img src={trashIcon} alt="delete icon"  className="h-15 w-15"/>}
          
        >
          <div className="flex flex-col items-center w-auto overflow-hidden">
            <p className="text-xs">
              {
                "Are you sure you want to delete the Faq? The action cannot be undone"
              }
            </p>
            <div className="flex gap-4 mt-6">
            
              <button
                className="border py-1 px-3 rounded w-40"
                onClick={handleCloseModals}
              >
                No, cancel
              </button>
              <button
                className="bg-[#C54600] text-white border rounded-md w-full py-3"
                onClick={handleDelete}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Yes, delete"}
              </button>
            </div>
          </div>
        </AppModal>

        <AppModal
          isOpen={showSuccessModal}
          setIsOpen={setSuccessModal}
          title="Faq successfully deleted"
          showCloseButton
          modalIcon={<img src={successIcon} alt="delete icon" />}
        >
          <div className="flex flex-col items-center h-28">
            <p className="text-xs">
              {"Your faq has been successfully deleted"}
            </p>
            <div className="flex justify-center mt-6">
              <button
                className="bg-blue-500 text-white border rounded-md w-[80px] py-3"
                onClick={reloadPage}
              >
                Done
              </button>
            </div>
          </div>
        </AppModal>
      </table>
    );
  }
};
